<template>
    <div class="adornmentDecorated_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <div class="content">
                <CourseTitle :title="formData.expansionCapacityName" contText="">
                </CourseTitle>
                <div class="development_space flex flex-wrap">
                    <div class="development_space_list">
                        <div v-for="(item, index) in formData.decorationExpansionCapacityVOS" :key="index" class="list_div"
                            v-html="item.expansionCapacityContent"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle :title="formData.decorationSalaryName" contText=" ">
                </CourseTitle>
                <div class="fatSalary">{{ formData.decorationSalaryIntroduce }}</div>
                <img class="icon_img pointer" @click="service" :src="formData.decorationSalaryImgUrl" alt="" />
            </div>
        </div>
        <div class="suitableLearning">
            <div class="content">
                <CourseTitle :title="formData.suitablePeopleName" contText=" ">
                </CourseTitle>
                <div class="suitableLearning_box flex">
                    <div class="suitableLearning_list" v-for="(item, index) in formData.decorationSuitablePeopleParamDTOS"
                        :key="index">
                        <div class="contetnBox ">
                            <div class="text">{{ item.suitablePeopleName }}</div>
                        </div>
                        <img class="icon_img" :src="item.suitablePeopleImgUrl" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="zero-based-learning">
            <div class="content">
                <CourseTitle :title="formData.zeroLearningName" contText=" ">
                </CourseTitle>
                <div class="zero-based-learning_box Between flex-wrap">
                    <div class="list  flex"
                        :class="index == 0 || index == 1 || index == 4 || index == 5 ? 'fl_list' : ' fr_list'"
                        v-for="(item, index) in formData.decorationZeroLearningParamDTOS" :key="index">
                        <img v-if="index == 0 || index == 1 || index == 4 || index == 5" class="icon_img"
                            :src="item.zeroLearningImgUrl" alt="" />
                        <div class="contetnBox ">
                            <div class="name">{{ item.zeroLearningName }}</div>
                            <div class="line"></div>
                            <div class="text">{{ item.zeroLearningIntroduce }}</div>
                            <div class="num">0{{ index + 1 }}</div>
                        </div>
                        <img v-if="index == 2 || index == 3 || index == 6 || index == 7" class="icon_img"
                            :src="item.zeroLearningImgUrl" alt="">
                    </div>

                </div>
            </div>
        </div>
        <div class="module3">
            <CoreCourses :coreCourseTitle="formData.coreCourseTitle"
                :registrationCoreCourse="formData.decorationCoreCourseVOS"
                :coreCourses="formData.decorationCoreCourseVOS[0]"></CoreCourses>
        </div>
        <div class="courseCharacteristics">
            <div class="content">
                <CourseTitle :title="formData.courseFeaturesName" contText=" ">
                </CourseTitle>
                <div class="courseCharacteristics_box ">
                    <div class="list Between" v-for="(item, index) in formData.decorationCourseFeaturesParamDTOS"
                        :key="index">
                        <img v-if="index % 2 != 0" class="icon_img" :src="item.courseFeaturesImgUrl" alt="" />
                        <div v-else class="whatLearn display column">
                            <div class="big">{{ item.firstName }}</div>
                            <div class="little display">{{ item.courseFeaturesIssue }}</div>
                            <div class="question">
                                ?
                            </div>
                        </div>
                        <div class="main display column">
                            <div class="title">{{ item.courseFeaturesName }}
                            </div>
                            <div class="text">{{ item.courseFeaturesIntroduce }}</div>
                        </div>
                        <div v-if="index % 2 != 0" class="whatLearn display column">
                            <div class="big">{{ item.firstName }}</div>
                            <div class="little display">{{ item.courseFeaturesIssue }}</div>
                            <div class="question">
                                ?
                            </div>
                        </div>
                        <img v-else class="icon_img" :src="item.courseFeaturesImgUrl" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="module4">
            <div class="">
                <CourseTitle :title="formData.teachingTeamName" :contText="formData.teachingTeamIntroduce">
                </CourseTitle>
                <Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
            </div>
        </div>
        <div class="module5">
            <!-- <SceneTeaching :title="formData.faceTeachingName" :contText="formData.faceTeachingIntroduce"
                :faceTeaching="formData.decorationFaceTeachingVOS"></SceneTeaching> -->
            <div class="">
                <CourseTitle :title="formData.faceTeachingName" :contText="formData.faceTeachingIntroduce"> </CourseTitle>
                <div class="scene_teaching">
                    <div class="navBox display">
                        <div class="list display pointer" :class="navIndex == index ? 'active' : ''"
                            v-for="(item, index) in formData.decorationFaceTeachingCategoryParamDTOS" :key="index"
                            @click="changeBtn(index, item)">
                            {{ item.categoryName }}
                        </div>
                    </div>
                    <div class="carousel_box ">
                        <el-carousel :interval="5000" :autoplay="false">
                            <el-carousel-item v-for="(list, index) in decorationFaceTeachingParamDTOS" :key="index">
                                <div class="serve_customer flex content">
                                    <div class="list" v-for="(item, index2) in list" :key="index2">
                                        <img class="swiper_img" :src="item.faceTeachingImgUrl" alt="">
                                        <div class="name display">{{ item.faceTeachingName }}</div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
        <div class="practicalOperation">
            <div class="content">
                <CourseTitle :title="formData.actualOperationName" contText=" ">
                </CourseTitle>
                <div class="practicalOperation_box">
                    <div class="list display column" v-for="(item, index) in formData.decorationActualOperationParamDTOS"
                        :key="index">
                        <img class="icon_img" :src="item.actualOperationImgUrl" alt="" />
                        <div class="list_name">{{ item.actualOperationName }}</div>
                        <div class="list_text">{{ item.actualOperationIntroduce }}</div>
                        <div class="btn display pointer" @click="service">了解更多</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="goodEdu">
            <div class="content">
                <CourseTitle :title="formData.learningBecomesName" contText=" ">
                </CourseTitle>
                <div class="goodEdu_box flex">
                    <div class="goodEdu_fl">
                        <div class="name nameA">{{ formData.learningBecomesIntroduce[0] }}</div>
                        <div class="name nameB">{{ formData.learningBecomesIntroduce[1] }}</div>
                        <img class="icon_img" src="@/assets/img/decoration/icon_right.png" alt="" />
                    </div>
                    <div class="goodEdu_fr flex">
                        <div class="list" v-for="(item, index) in formData.decorationLearningBecomesParamDTOS" :key="index">
                            <img class="icon_img" :src="item.learningBecomesImgUrl" alt="" />
                            <div class="cont_box display column">
                                <div class="text">{{ item.learningBecomesName }}</div>
                                <img class="icon_img" src="@/assets/img/decoration/icon_x.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="teachingVideo">

            <CourseTitle :title="formData.teachingVideoName" :contText="formData.teachingVideoIntroduce">
            </CourseTitle>
            <div class="navBar display">
                <div class="list display pointer" :class="navBarIndex == index ? 'activeList' : ''"
                    v-for="(item, index) in formData.decorationTeachingVideoCategoryParamDTOS" :key="index"
                    @click="changeNav(item, index)">
                    {{ item.categoryName }}
                </div>
            </div>

            <div class="why_textual_research">
                <div class="carousel_box">
                    <el-carousel :interval="5000">
                        <el-carousel-item v-for="(item, index) in decorationTeachingVideoParamDTOS" :key="index">
                            <div class="el_carousel_cont display column">
                                <video class="video_div" controls="controls" :playsinline="true"
                                    :src="item.teachingVideoVideoUrl"></video>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>


        </div>
        <div class="module6">
            <CollegeWork :title="formData.studentWorksName" :contText="formData.studentWorksIntroduce"
                :studentsWork="formData.decorationStudentWorksVOS"></CollegeWork>
        </div>

        <div class="studentStyle">
            <div class="content">
                <CourseTitle :title="formData.excellentStudentStyleName"
                    :contText="formData.excellentStudentStyleIntroduce">
                </CourseTitle>
                <div class="main ">
                    <img class="icon_img" :src="formData.excellentStudentStyleImgUrl" alt="" />
                </div>
            </div>
        </div>
        <div class="module8">
            <SignUp :courseType="4" :courseId="this.formData.id"></SignUp>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import CoreCourses from '@/components/coreCourses'
import Lecturer from '@/components/lecturer'
import SceneTeaching from '@/components/sceneTeaching'
import CollegeWork from '@/components/collegeWork'
import SignUp from '@/components/signUp'
export default {
    components: {
        BannerImg,
        CourseTitle,
        CoreCourses,
        Lecturer,
        SceneTeaching,
        CollegeWork,
        SignUp,
    },
    data() {
        return {
            navIndex: 0,
            formData: {},
            list: [],
            navBarIndex: 0,
            decorationTeachingVideoParamDTOS: [],
            decorationFaceTeachingParamDTOS: [],

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0
        this.init()
    },
    methods: {
        init() {
            this.api.findDecorationFurnish().then((res) => {
                if (res.code == 0) {
                    res.data.decorationStudentWorksVOS.forEach((item) => {
                        item.studentWorksImgUrl = item.studentWorksImgUrl.split(',')
                    })
                    res.data.learningBecomesIntroduce = res.data.learningBecomesIntroduce.split(' ')
                    if (res.data.decorationCourseFeaturesParamDTOS.length != 0) {
                        res.data.decorationCourseFeaturesParamDTOS.forEach(item => {
                            item.firstName = item.courseFeaturesIssue.slice(0, 1)
                            item.courseFeaturesIssue = item.courseFeaturesIssue.slice(1)

                        })
                    }
                    this.formData = res.data

                    if (res.data.decorationTeachingVideoCategoryParamDTOS.length != 0) {
                        this.decorationTeachingVideoParamDTOS = res.data.decorationTeachingVideoCategoryParamDTOS[0].decorationTeachingVideoParamDTOS

                    }
                    if (res.data.decorationFaceTeachingCategoryParamDTOS.length != 0) {
                        this.decorationFaceTeachingParamDTOS = res.data.decorationFaceTeachingCategoryParamDTOS[0].decorationFaceTeachingParamDTOS
                    }
                    this.decorationFaceTeachingParamDTOS = this.carousel(
                        this.decorationFaceTeachingParamDTOS,
                        3
                    )

                }
            })
        },
        // 轮播图格式化
        carousel(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }
            }
            return (dataList = [...newDataList])
        },
        // 切换现场教学
        changeNav(item, index) {
            console.log(item);
            this.navBarIndex = index
            this.decorationTeachingVideoParamDTOS = item.decorationTeachingVideoParamDTOS
        },
        changeBtn(index, item) {
            this.navIndex = index
            console.log(item);
            this.decorationFaceTeachingParamDTOS = this.carousel(
                item.decorationFaceTeachingParamDTOS,
                3
            )
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.adornmentDecorated_page {
    .module1 {
        height: 750px;
        background: url('../../assets/img/course/bg15.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 110px;

        .development_space {
            width: 1200px;
            height: 750px;
            background: url('../../assets/img/course/bg16.png') no-repeat;
            background-size: 100% 100%;
            margin-top: -130px;
            position: relative;

            .list_div {
                width: 293px;
                line-height: 24px;
            }

            .list_div:nth-child(1) {
                position: absolute;
                top: 220px;
                left: 0px;
            }

            .list_div:nth-child(2) {
                position: absolute;
                top: 220px;
                right: 0px;
            }

            .list_div:nth-child(3) {
                position: absolute;
                top: 360px;
                left: 0px;
            }

            .list_div:nth-child(4) {
                position: absolute;
                top: 360px;
                right: 0px;
            }

            .list_div:nth-child(5) {
                position: absolute;
                bottom: 110px;
                left: 0px;
            }

            .list_div:nth-child(6) {
                position: absolute;
                bottom: 110px;
                right: 0px;
            }
        }
    }

    .module2 {
        padding-bottom: 100px;

        .fatSalary {
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FF8C2A;
            line-height: 48px;
            text-align: center;
            margin-top: -20px;
        }

        .icon_img {
            width: 1200px;
            height: 400px;
            margin-top: 22px;
        }
    }

    .module4 {
        background: #fafafa;
        padding-bottom: 156px;
    }

    .module5 {
        .scene_teaching {
            width: 1400px;
            margin: 0 auto;
            padding-bottom: 100px;
        }

        .carousel_box {
            margin-top: 40px;

            ::v-deep.swiper-container {
                height: 336px;
                padding-left: 3px;
            }

            ::v-deep.el-carousel__container {
                height: 340px;
            }

            ::v-deep.el-carousel {
                .el-carousel__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .el-carousel__container {
                    .el-carousel__arrow {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }
            }

            .list:nth-child(1) {
                margin-left: 0 !important;
            }

            .list {
                width: 360px;
                height: 334px;
                margin: 0px 30px;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);

                .swiper_img {
                    width: 361px;
                    height: 260px;
                }

                .name {
                    width: 100%;
                    height: 70px;
                    background: #fff;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }

        .navBox {
            margin-top: 22px;

            .list {
                height: 48px;
                background: #FAFAFA;
                border: 1px solid #DEDEDE;
                border-radius: 24px;
                padding: 0 38px;
                margin: 0 11px
            }

            .active {
                background: #003C7E !important;
                color: white !important;
            }
        }
    }


    .suitableLearning {
        height: 630px;
        background: url('../../assets/img/decoration/bg1.png') no-repeat;
        background-size: 100% 100%;

        .suitableLearning_box {
            margin-top: 110px;

            .suitableLearning_list:nth-child(1) {
                margin-left: 0 !important;
            }

            .suitableLearning_list {
                position: relative;
                margin-left: 86px;

                .icon_img {
                    width: 228px;
                    height: 208px;
                }

                .contetnBox {
                    width: 220px;
                    height: 108px;
                    background: url('../../assets/img/decoration/bg2.png') no-repeat;
                    background-size: 100% 100%;
                    padding: 20px;
                    box-sizing: border-box;
                    margin-bottom: -40px;
                    position: absolute;
                    top: -72px;
                    left: 17px;

                    .text {
                        width: 197px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .zero-based-learning {
        padding-bottom: 90px;

        .zero-based-learning_box {
            margin-top: 40px;

            .fr_list {
                text-align: right;

                .contetnBox {
                    padding-right: 25px;

                    .line {
                        margin-left: auto;
                        margin-top: 20px;
                    }

                    .text {
                        margin-left: auto;
                        margin-top: 20px;
                    }

                    .num {
                        position: absolute;
                        left: 27px;
                        top: 37px;
                    }
                }

                .text {
                    width: 100%;
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .fl_list {


                .contetnBox {
                    padding-left: 36px;

                    .line {

                        margin-top: 20px;
                    }

                    .text {
                        margin-top: 20px;
                    }

                    .num {
                        position: absolute;
                        right: 27px;
                        top: 37px;
                    }
                }

                .text {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .list {
                width: 580px;
                height: 200px;
                box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.5);
                margin-bottom: 30px;

                .icon_img {
                    width: 290px;
                    height: 200px;
                }

                .contetnBox {
                    width: 290px;
                    height: 200px;
                    padding-top: 37px;
                    box-sizing: border-box;
                    position: relative;

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                    }

                    .line {
                        width: 48px;
                        height: 4px;
                        background: #FF8C2A;
                    }

                    .text {
                        width: 194px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 24px;
                    }

                    .num {
                        font-size: 60px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #E6E6E6;
                        line-height: 38px;
                    }
                }
            }
        }
    }

    .courseCharacteristics {
        padding-bottom: 30px;

        .courseCharacteristics_box {
            margin-top: 45px;

            .list {
                width: 1200px;
                height: 160px;
                background: #FFFFFF;
                box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.52);
                margin-bottom: 30px;

                .whatLearn {
                    width: 58px;
                    height: 160px;
                    background: #003c7e;

                    .big {
                        font-size: 32px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                    }

                    .little {
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 20px;
                        writing-mode: tb-rl;
                        text-align: center;
                    }

                    .question {
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 20px;
                    }
                }

                .main {
                    width: 792px;

                    .title {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 36px;
                    }

                    .text {
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        color: #666666;
                        line-height: 36px;
                    }
                }

                .icon_img {
                    width: 350px;
                    height: 160px;
                }
            }
        }
    }

    .practicalOperation {
        width: 100%;
        height: 1090px;
        background: url('../../assets/img/decoration/bg3.png') no-repeat;
        background-size: 100% 100%;

        .practicalOperation_box {
            width: 100%;
            height: 860px;
            background: url('../../assets/img/decoration/bg4.png') no-repeat;
            background-size: 100% 100%;
            position: relative;

            .list:nth-child(1) {
                top: 39px;
                left: 123px;
            }

            .list:nth-child(2) {
                top: 39px;
                right: 123px;
            }

            .list:nth-child(3) {
                top: 349px;
                right: 463px;
            }

            .list {
                position: absolute;

                .icon_img {
                    width: 280px;
                    height: 280px;
                    border-radius: 50%;
                }

                .list_name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 28px;
                }

                .list_text {
                    width: 256px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 24px;
                    margin-top: 18px;
                    text-align: center;
                }

                .btn {
                    width: 118px;
                    height: 40px;
                    background: #003C7E;
                    border-radius: 5px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 18px;
                }
            }
        }
    }

    .goodEdu {
        height: 628px;

        .goodEdu_box {
            margin-top: 40px;

            .goodEdu_fl {
                min-width: 743px;
                height: 330px;
                background: #003C7E;
                border-radius: 50px 0px 50px 0px;
                padding-top: 90px;
                padding-left: 40px;


                .name {
                    width: 250px;
                    font-size: 30px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 40px;
                }

                .icon_img {
                    width: 82px;
                    height: 14px;
                    margin-top: 20px;
                }
            }

            .goodEdu_fr {
                margin-left: -440px;
                margin-top: 40px;

                .list {
                    margin-right: 22px;
                    position: relative;

                    .icon_img {
                        width: 280px;
                        height: 240px;
                    }

                    .cont_box {
                        width: 280px;
                        height: 240px;
                        position: absolute;
                        left: 0;
                        top: 0;

                        .text {
                            width: 203px;
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 24px;
                        }

                        .icon_img {
                            width: 115px;
                            height: 115px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            margin-top: -57px;
                            margin-left: -57px;
                        }
                    }
                }
            }
        }
    }

    .teachingVideo {
        height: 1048px;
        background: url('../../assets/img/specialOperations/bg8.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 110px;

        .navBar {
            margin-top: 21px;

            .list {
                height: 46px;
                background: #eeeeee;
                border-radius: 3px;
                padding: 0px 36px;
                box-sizing: border-box;
                margin: 0px 9px;
                border-radius: 24px;
            }

            .activeList {
                background: #003C7E !important;
                color: #fff;
            }
        }

        .why_textual_research {
            width: 1400px;
            margin: 0 auto;

            .el_carousel_cont {
                margin-top: 40px;

                .video_div {
                    width: 1200px;
                    height: 670px;
                }
            }

            ::v-deep.el-carousel__container {
                height: 700px;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            ::v-deep.el-carousel {
                .el-carousel__item {}

                .el-carousel__container {
                    .el-carousel__arrow {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }
            }
        }
    }

    .studentStyle {
        background: #fafafa;
        padding-bottom: 100px;

        .icon_img {
            width: 100%;
            height: 612px;
            margin-top: 45px;
        }
    }
}
</style>
<style lang="scss">
.swiper-button-prev:after,
.swiper-button-next:after {
    content: none;
}
</style>